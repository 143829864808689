import React from "react";
import { Route, Routes } from "react-router-dom";
import BABD from "./BABD";
import Home from "./Home";
export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BABD />} />
        <Route index element={<BABD />} />
        <Route path="Home" element={<Home />} />
        <Route path="*" element={<BABD />} />
      </Routes>
    </div>
  );
}